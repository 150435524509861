




















































































































import CashPaymentButton from "@/components/Payments/CashPaymentButton.vue";
import EditableAmount from "@/components/Payments/EditableAmount.vue";
import PaymentStatus from "@/components/Payments/PaymentStatus.vue";
import { DropInPaymentViewItem } from "@/types/Payment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TablePaymentMethod from "@/components/Payments/TablePaymentMethod.vue";
import BackButton from "@/assets/icons/saxcons/back-square-linear.svg";
import ProgramIcon from "@/assets/icons/navigation/programs.svg";

@Component({
  name: "drop-in-mobile-payment-item",
  components: {
    BackButton,
    TablePaymentMethod,
    CashPaymentButton,
    EditableAmount,
    PaymentStatus,
    ProgramIcon
  }
})
export default class DropInMobilePaymentItem extends Vue {
  @Prop({ required: true })
  item!: DropInPaymentViewItem;

  @Prop({ required: false, default: false })
  isParentLoading!: boolean;

  @Prop({ required: true })
  paymentMethodsLoaded!: boolean;

  @Prop({ required: false, default: false })
  isReport!: boolean;

  detailsOpen: number | null = null;

  get showActions(): boolean {
    if (this.item.status === "success") {
      return false;
    } else if (this.item.status === "processing") {
      return false;
    }
    return true;
  }

  get paidMessage(): string | null {
    if (this.item.status === "success") {
      if (this.item.paidInCash) {
        return "Paid by Cash";
      }
      return "Paid Online";
    }
    return null;
  }

  changeAmount(itemId: string, amount: number) {
    this.$emit("amountUpdated", itemId, amount);
  }
  changeCashStatus(uid: string, item: DropInPaymentViewItem) {
    this.$emit("changeCashStatus", uid, item);
  }
}
